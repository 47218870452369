<!-- ВСЕ ПУЛЫ -->
<template>
  <section class="xbts-defi-asset">
    <div class="card w-100 overflow-hidden">
      <div class="row">
        <div class="col-md-3 p-0 pl-2 overflow-hidden">
          <div class="input-group">
            <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
                            <span class="input-group-text" id="search">
                                <i class="ti-search"></i>
                            </span>
            </div>
            <input
                aria-describedby="search"
                aria-label="search"
                class="form-control text-uppercase"
                id="navbar-search-input"
                :placeholder="$t('pool.search_pools')"
                type="text"
                v-model="lpFilter"
                @input="search"
            />
          </div>
        </div>
        <div class="col-md-9 p-0 bg-dark overflow-hidden">
          <div aria-label="Featured Assets" class="btn-group w-100 btn-group-sm mt-1" role="group">
            <button @click="search('BTC')" v-b-tooltip.html.top title="XBTSX.BTC Bitcoin" class="btn btn-dark" type="button">
              <img src="images/assets/btc.png" width="20px"/> BTC
            </button>
            <button @click="search('BTS')" v-b-tooltip.html.top title="BTS BitShares Core Asset" class="btn btn-dark" type="button">
              <img src="images/assets/bts.png" width="20px"/> BTS
            </button>
            <button @click="search('USDT')" v-b-tooltip.html.top title="XBTSX.USDT Tether USD" class="btn btn-dark" type="button">
              <img src="images/assets/usdt.png" width="20px"/> USDT
            </button>
            <button @click="search('STH')" v-b-tooltip.html.top title="XBTSX.STH SmartHoldem" class="btn btn-dark" type="button">
              <img src="images/assets/sth.png" width="20px"/> STH
            </button>
            <button @click="search('HIVE')" v-b-tooltip.html.top title="XBTSX.HIVE" class="btn btn-dark" type="button">
              <img src="images/assets/hive.png" width="20px"/> HIVE
            </button>
            <button v-if="!$root.isMobile" @click="search('EOS')" v-b-tooltip.html.top title="XBTSX.EOS Asset" class="btn btn-dark" type="button">
              <img src="images/assets/eos.png" width="20px"/> EOS
            </button>
            <button v-if="!$root.isMobile" @click="search('DEFI')" v-b-tooltip.html.top title="DEFI Asset" class="btn btn-dark" type="button">
              <img src="images/assets/defi.png" width="20px"/> DEFI
            </button>
            <button v-if="!$root.isMobile" @click="search('CNY')" v-b-tooltip.html.top title="bitCNY Smart Asset" class="btn btn-dark" type="button">
              <img src="images/assets/cny.png" width="20px"/> CNY
            </button>

          </div>

        </div>
      </div>



      <div class="row">
        <div class="ml-auto mr-auto" v-show="!searchResult.length">
          <intersecting-circles-spinner
              :animation-duration="1200"
              :size="70"
              color="#f9a901"
          />
          <!--<p v-show="isNotFound" class="text-danger text-center">Pools is Not Found</p>-->
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <table class="table table-striped lp-search-result" v-if="searchResult">
                <thead>
                  <tr>
                    <th v-show="!$root.isMobile">{{$t('market.price')}} ({{searchResult.length}})</th>
                    <th @click="setSort('A.balance')">A</th>
                    <th @click="setSort('B.balance')">B</th>
                    <th v-show="!$root.isMobile" @click="setSort('SHARE.amount')">{{ $t('pool.liquidity') }}</th>
                    <th v-show="!$root.isMobile" @click="setSort('APY')">
                      <span class="float-right">{{ $t('pool.1yf') }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                <tr class="cursor-pointer text-white" v-for="(item, idx) in orderedPools" v-bind:key="idx" @click="routeTo('/pool/'+item.SHARE.asset.symbol)">
                  <td v-show="!$root.isMobile" :title="item.SHARE.asset.description.main + ' ' + (item.SHARE.asset.description.short_name ? '(' + item.SHARE.asset.description.short_name + ')': '') + '<br/> Taker ' + item.POOL.taker_fee_percent / 100 + '% / Withdraw ' + item.POOL.withdrawal_fee_percent / 100+'% <br/>' + item.SHARE.asset.symbol" v-b-tooltip.html.left>
                    <!--<span class="small">{{item.POOL.id}} {{item.SHARE.asset.symbol}} {{numTransform(item.SHARE.supply)}}</span>-->
                    <p class="small" v-if="item.A.balance > 0">
                      {{item.B.asset.symbol.replace('XBTSX.','')}}/{{item.A.asset.symbol.replace('XBTSX.','')}} <span>{{(item.B.balance / item.A.balance).toFixed(item.B.asset.precision)}}</span>
                      <br/>
                      {{item.A.asset.symbol.replace('XBTSX.','')}}/{{item.B.asset.symbol.replace('XBTSX.','')}} <span>{{(item.A.balance / item.B.balance).toFixed(8)}}</span>
                    </p>
                  </td>
                  <td>
                    <span v-b-tooltip.html.left :title="item.A.asset.symbol + ' ' + $t('pool.vol24')+' ' + item.A.vol24" class="small text-white-50 ml-2">
                      <img v-if="allAssets[item.A.asset.symbol]" :src="'images/assets/' + item.A.asset.symbol.toLowerCase() + '.png'" class="mr-2"/>
                      <vue-letter-avatar v-else :name="assetToSymbol(item.A.asset.symbol)" size='24' :rounded=true class="mr-2" />
                    </span>
                    {{numTransform(item.A.balance)}} <span class="text-success small" v-show="item.A.asset.bitAsset">BIT</span>{{item.A.asset.symbol.replace('XBTSX.','')}}
                    <br/>
                    <div class="small text-white-50 ml-3" style="padding-left: 28px;">
                      <span class="text-white-50">VOL {{numTransform(item.A.vol24)}}</span>
                      <span class="pl-4 mt-1 text-white" v-if="item.A.balance > 0 && $root.isMobile">
                        <p class="text-small">
                        <span>{{(item.B.balance / item.A.balance).toFixed(item.B.asset.precision)}}</span> <span class="small">{{item.B.asset.symbol.replace('XBTSX.','')}}</span>
                        </p>
                      </span>
                    </div>

                  </td>
                  <td>
                  <span v-b-tooltip.html.left :title="item.B.asset.symbol + ' ' + $t('pool.vol24')+' ' + item.B.vol24">
                    <img v-if="allAssets[item.B.asset.symbol]" :src="'images/assets/' + item.B.asset.symbol.toLowerCase() + '.png'" class="mr-2"/>
                    <vue-letter-avatar v-else :name="assetToSymbol(item.B.asset.symbol)" size='24' :rounded=true class="mr-2" />
                  </span>


                    {{numTransform(item.B.balance)}} <span class="text-success small" v-show="item.B.asset.bitAsset">BIT</span>{{item.B.asset.symbol.replace('XBTSX.','')}}
                    <br>
                    <div class="small text-white-50 ml-2" style="padding-left: 28px;">
                      <span class="">VOL {{numTransform(item.B.vol24)}}</span>
                      <span class="text-white" v-if="item.B.balance > 0 && $root.isMobile">
                        <p class="text-small">
                          <span>{{(item.A.balance / item.B.balance).toFixed(item.A.asset.precision)}}</span> <span class="small">{{item.A.asset.symbol.replace('XBTSX.','')}}</span>
                        </p>
                      </span>
                    </div>
                  </td>
                  <td v-show="!$root.isMobile">

                    <span v-show="item.SHARE.amount * prices[currency].price > 9999">{{ currSymbols[currency] }} {{numTransform(item.SHARE.amount * prices[currency].price)}}</span>
                    <span v-show="item.SHARE.amount > 0 && item.SHARE.amount * prices[currency].price < 10000">{{ currSymbols[currency] }} {{formatNum(item.SHARE.amount * prices[currency].price, '# ##0.')}}</span>
                  </td>
                  <td v-show="!$root.isMobile">
                    <span v-if="item.APY > 0" class="float-right badge badge-gradient-success text-dark font-weight-bold border-secondary" :title="$t('pool.apy_tip')" v-b-tooltip.html.left>{{ (item.APY).toFixed(2) }}%</span>
                    <span v-if="item.APY < 0.001" class="float-right badge badge-secondary">0.00%</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="5" class="text-right">{{$t('pool.total_liquidity')}} {{ currSymbols[currency] }} {{numTransform(sumAmountBts * prices[currency].price)}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import eventBus from '@/plugins/event-bus';
import {currSymbols, search} from "@/config";
import {IntersectingCirclesSpinner} from 'epic-spinners'

export default {
  name: "Lp",
  components: {
    IntersectingCirclesSpinner,
  },
  data() {
    return {
      lpFilter: "",
      currSymbols: currSymbols,
      searchResult: [],
      allAssets: {},
      sort: {
        field: 'SHARE.amount',
        direction: 'desc'
      },
      isNotFound: false,
      sumAmountBts: 0,
    }
  },
  watch: {
    '$route': {
      handler: async function (to, from) {
        if (this.$route.query.a) {
          await this.search(this.$route.query.a.toUpperCase());
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // сортировка
    orderedPools: function () {
      return _.orderBy(this.searchResult, this.sort.field, this.sort.direction)
    },
    prices() {
      return this.$store.getters['xbts/featuredPrice']['bts'];
    },
    currency() {
      return this.$store.getters['xbts/currency'];
    },

  },
  methods: {
    async setSort(field = 'APY') {
      this.sort.direction === 'desc' ? this.sort.direction = 'asc' : this.sort.direction = 'desc';
      this.sort.field = field;
    },
    async search(asset = '') {
      this.isNotFound = true;
      if (asset.length >= 1) {
        this.lpFilter = asset;
      }
      if (this.lpFilter.length >= 1) {
        this.searchResult = [];
        this.lpFilter = this.lpFilter.toUpperCase();
        const symbol = this.lpFilter.toUpperCase();
        if (search[symbol]) {
          this.searchResult = await this.$store.dispatch('lp/LpSearch', search[symbol]);

          try {
            this.sumAmountBts = 0;
            for (let i=0; i < this.searchResult.length; i++) {
              this.sumAmountBts = this.sumAmountBts + this.searchResult[i].SHARE.amount;
            }
            this.isNotFound = false;
          } catch (e) {
            //console.log('err get pools');
          }


        }
      } else {
        const symbol = 'STH';
        this.searchResult = await this.$store.dispatch('lp/LpSearch', search[symbol]);
      }
    },
  },
  async created() {
    this.allAssets = await this.knownAssets();

    if (this.$route.query.a) {
      await this.search(this.$route.query.a.toUpperCase());
    } else {
      await this.search('STH');
    }
  }
}
</script>

<style>
  .lp-search-result img {
    width:24px !important;
    height:24px !important;
  }
</style>
